<template>
  <footer>
    <div class="footer-container">
      <div class="col-sm-12 col-md-9 col-lg-9 row">
        <div class="footer-section col-sm-12 col-md-6 col-lg-6">
          <img class="logo" src="assets/img/Geopin-Logo.svg" alt="">
          <p>Bootstrapping accelerator termsheet partnership non-disclosure agreement. Research & development
            stealth user experience direct mailing crowdfunding niche market gamification crowdsource
            facebook
            iPhone value proposition seed money.</p>

          <div class="social-icons">
            <p>Follow us</p>
            <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
            <a href="#"><i class="fa-brands fa-twitter"></i></a>
            <a href="#"><i class="fa-brands fa-instagram"></i></a>
          </div>
        </div>
        <div class="footer-section col-sm-12 col-md-3 col-lg-3">
          <h3>Features</h3>
          <ul>
            <li>Organic SEO</li>
            <li>Local SEO</li>
            <li>Social Media Marketing</li>
            <li>PPC Marketing</li>
            <li>SEO Analysis</li>
            <li>Reputation Management</li>
          </ul>
        </div>
        <div class="footer-section col-sm-12 col-md-3 col-lg-3">
          <h3>Company</h3>
          <ul>
            <li>About</li>
            <li>FAQ</li>
            <li>Our Team</li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
}
</script>
