<template>
  <header id="head">
    <nav class="container-60">
      <div class="logo">
        <img v-if="!scrolled" src="assets/img/Geopin-Logo-wh.svg" alt="logo">
        <img v-if="scrolled" src="assets/img/Geopin-Logo.svg" alt="Logo White" class="logo-scrolled">
      </div>
      <div class="menu-toggle" id="mobile-menu">
        <i class="fas fa-bars"></i>
      </div>
      <ul class="nav-links">
        <li @click="scrollToElement('how-it-works')"><a href="javascript:void(0);">Nasıl Çalışır?</a></li>
        <li @click="scrollToElement('feature')"><a href="javascript:void(0);">Özellikler</a></li>
        <li @click="scrollToElement('pricing')"><a href="javascript:void(0);">Fiyatlandırma</a></li>
        <li @click="scrollToElement('faq')"><a href="javascript:void(0);">Faq</a></li>
        <li @click="scrollToElement('download')"><a href="javascript:void(0);"><button><b>Uygulamayı
                Yükle</b></button></a></li>
      </ul>
    </nav>
  </header>

  <main>
    <section class="d-flex justify-content-center w-full col-12">
      <div class="hero row">
        <div data-aos="fade-right" data-aos-duration="1000" class="content col-sm-12 col-md-6 col-lg-7">
          <h1>Yolları StartUP ile Güvenli Hale Getirin</h1>
          <p>Gerçek zamanlı güncellemeler ile her zaman en güvenli ve en hızlı rotayı keşfedin. Map App,
            yolculuğunuzu daha güvenli ve verimli hale getiriyor.</p>
          <div class="buttons">
            <a href="#" class="google-play">
              <div class="store-btn">
                <img src="assets/img/google play.png" width="40" alt="Google Play store">
                <div>
                  <p style="font-size: smaller;">GET IT ON</p>
                  <p class="fw-500">Google Play</p>
                </div>
              </div>
            </a>
            <a href="#" class="apple-store">
              <div class="store-btn">
                <img src="assets/img/apple.png" width="40" alt="Apple store">
                <div>
                  <p style="font-size: smaller;">Available on the</p>
                  <p class="fw-500">App Store</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000" class="mockup col-sm-12 col-md-6 col-lg-5">
          <img src="assets/img/phone.png" alt="Telefon Mockup">
        </div>
      </div>
    </section>
    <section id="how-it-works" class="how-it-works col-sm-12 col-md-8 col-lg-6">
      <h2 data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">Nasıl Çalışır?</h2>
      <h3 data-aos="fade-up" data-aos-duration="500" data-aos-delay="500"><b>Basit ve Hızlı</b></h3>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500"
        class="step row justify-content-center align-items-center">
        <div class="step col-sm-12 col-md-4 col-lg-3">
          <lord-icon src="https://cdn.lordicon.com/tdtlrbly.json" trigger="hover"
            colors="primary:#121331,secondary:#3b43f2" style="width:100px;height:100px">
          </lord-icon>
          <h4><b>Share</b></h4>
          <p>Launch party pitch technology user experience innovator buzz stealth MVP business model.</p>
        </div>
        <div class="right-arrow col-md-1 col-lg-1"><img src="assets/img/right.svg" alt="Right Arrow"></div>
        <div class="step col-sm-12 col-md-4 col-lg-3">
          <lord-icon src="https://cdn.lordicon.com/jdgfsfzr.json" trigger="hover"
            colors="primary:#121331,secondary:#3b43f2" style="width:100px;height:100px">
          </lord-icon>
          <h4><b>Send Message</b></h4>
          <p>Launch party pitch technology user experience innovator buzz stealth MVP business model.</p>
        </div>
        <div class="right-arrow col-md-1 col-lg-1"><img src="assets/img/right.svg" alt="Right Arrow"></div>
        <div class="step col-sm-12 col-md-4 col-lg-3">
          <lord-icon src="https://cdn.lordicon.com/rpviwvwn.json" trigger="hover"
            colors="primary:#121331,secondary:#3b43f2" style="width:100px;height:100px">
          </lord-icon>
          <h4>Search</h4>
          <p>Launch party pitch technology user experience innovator buzz stealth MVP business model.</p>
        </div>
      </div>
    </section>
    <section id="feature" class="feature-section col-12">
      <div style="flex-direction: column;"
        class="col-sm-12 col-md-10 col-lg-10 d-flex justify-content-center flex-wrap">
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" class="col-sm-12 col-md-8 col-lg-5 m-auto">
          <h2>New Personal Finance App</h2>
          <p style="max-width: 700px; margin: auto; margin-bottom: 50px;">Landy lets you take control of your money,
            balance your income and expenses, and understand where
            your
            money goes.</p>
        </div>

        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" class="features">
          <div class="feature-left">
            <div class="feature-item">
              <h3>Easy access</h3>
              <p>Take Ehya wherever you go so that you know what’s going on with your money at all times.
              </p>
            </div>
            <div class="feature-item">
              <h3>Track your spending</h3>
              <p>Follow your plan by keeping track of your spending throughout the month.</p>
            </div>
            <div class="feature-item">
              <h3>Activity statistics</h3>
              <p>See statistics of income and expenses and displaying the forecast in the next period.</p>
            </div>
          </div>
          <div data-aos-delay="500" data-aos="fade-in" data-aos-duration="1000" class="phone-mockup">
            <img src="assets/img/phone.png" alt="Phone Mockup">
          </div>
          <div class="feature-right">
            <div class="feature-item">
              <h3>Secure transfer</h3>
              <p>Take Ehya wherever you go so that you know what’s going on with your money at all times.
              </p>
            </div>
            <div class="feature-item">
              <h3>Budget that works</h3>
              <p>Create budgets you can actually stick to, and see how you’re spending your money.</p>
            </div>
            <div class="feature-item">
              <h3>Total control</h3>
              <p>Take a full control of your expenses. Landy will help you to do that anywhere anytime.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="pricing" class="pricing-section col-12">
      <h2 data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">Ücretlendirme</h2>
      <h3 data-aos="fade-up" data-aos-duration="500" data-aos-delay="500"><b>Choose the right plan</b></h3>
      <div class="pricing-cards">
        <div data-aos-delay="500" data-aos="fade-right" data-aos-duration="700" class="card">
          <h4>Ücretsiz Plan</h4>
          <p class="price">$0</p>
          <ul>
            <li><span class="check">✔</span> 5GB Disk Space</li>
            <li><span class="check">✔</span> 100 Email Accounts</li>
            <li class="not"><span class="cross">✖</span> Unlimited Bandwidth</li>
            <li class="not"><span class="cross">✖</span> Write your text here</li>
            <li class="not"><span class="cross">✖</span> Unlimited Bandwidth</li>
          </ul>
          <button>Planı Seç</button>
        </div>
        <div data-aos-delay="500" data-aos="fade-in" data-aos-duration="700" class="card premium">
          <h4>Premium Plan</h4>
          <p class="price">$9</p>
          <ul>
            <li><span class="check">✔</span> 5GB Disk Space</li>
            <li><span class="check">✔</span> 100 Email Accounts</li>
            <li><span class="check">✔</span> Unlimited Bandwidth</li>
            <li><span class="check">✔</span> Write your text here</li>
            <li><span class="check">✔</span> Unlimited Bandwidth</li>
          </ul>
          <button>Planı Seç</button>
        </div>
      </div>
    </section>
    <section id="faq" class="container-80 FAQ">
      <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" class="col-12 faq-head">
        <h2 class="text-light">Sorularınız Mı Var?</h2>
        <h1 class="text-light">Yardımcı Olabiliriz</h1>
      </div>
      <div class="faq-body col-sm-12 col-md-6 col-lg-6">
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="200" class="question">
          What is OrionApp for?
          <i class="icon fa-solid fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi est cumque cum
            sint similique iusto fuga inventore maiores doloremque, quas, unde libero tenetur eius, velit
            molestias amet provident eligendi consequuntur!</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="450" class="question">
          How OrionApp works?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi est cumque cum
            sint similique iusto fuga inventore maiores doloremque, quas, unde libero tenetur eius, velit
            molestias amet provident eligendi consequuntur!</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="700" class="question">
          How to create account on OrionApp?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi est cumque cum
            sint similique iusto fuga inventore maiores doloremque, quas, unde libero tenetur eius, velit
            molestias amet provident eligendi consequuntur!</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="950" class="question">
          Write your question here?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quasi sapiente incidunt officia
            sequi perferendis accusantium facilis totam commodi necessitatibus assumenda temporibus harum
            accusamus consequuntur, deserunt aperiam voluptates dignissimos nobis!
          </div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="1100" class="question">
          How to create account on OrionApp?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda pariatur, qui
            maiores iste iusto eum autem nesciunt sit eius quasi fuga voluptatum facere sint. Maxime
            reiciendis voluptatibus debitis neque asperiores?</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="1200" class="question">
          Write your question here?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda pariatur, qui maiores iste
            iusto eum autem nesciunt sit eius quasi fuga voluptatum facere sint. Maxime reiciendis
            voluptatibus debitis neque asperiores?
          </div>
        </div>
      </div>
      <div class="faq-body col-sm-12 col-md-6 col-lg-6 more">
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="200" class="question">
          What is OrionApp for?
          <i class="icon fa-solid fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi est cumque cum
            sint similique iusto fuga inventore maiores doloremque, quas, unde libero tenetur eius, velit
            molestias amet provident eligendi consequuntur!</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="400" class="question">
          How OrionApp works?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi est cumque cum
            sint similique iusto fuga inventore maiores doloremque, quas, unde libero tenetur eius, velit
            molestias amet provident eligendi consequuntur!</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="600" class="question">
          How to create account on OrionApp?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi est cumque cum
            sint similique iusto fuga inventore maiores doloremque, quas, unde libero tenetur eius, velit
            molestias amet provident eligendi consequuntur!</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="800" class="question">
          Write your question here?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero quasi sapiente incidunt officia
            sequi perferendis accusantium facilis totam commodi necessitatibus assumenda temporibus harum
            accusamus consequuntur, deserunt aperiam voluptates dignissimos nobis!
          </div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="1000" class="question">
          How to create account on OrionApp?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda pariatur, qui
            maiores iste iusto eum autem nesciunt sit eius quasi fuga voluptatum facere sint. Maxime
            reiciendis voluptatibus debitis neque asperiores?</div>
        </div>
        <div data-aos-delay="500" data-aos="zoom-in" data-aos-duration="2000" class="question">
          Write your question here?
          <i class="icon fas fa-chevron-down"></i>
          <div class="answer">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda pariatur, qui maiores iste
            iusto eum autem nesciunt sit eius quasi fuga voluptatum facere sint. Maxime reiciendis
            voluptatibus debitis neque asperiores?
          </div>
        </div>
      </div>
      <button id="show-more"><b>Hepsini Gör</b></button>
      <button id="hide"><b>Daha AZ Göster</b></button>
    </section>
    <section id="download" class="container-80 download d-flex justify-content-center">
      <div class="container-phone">
        <div data-aos="fade-right" data-aos-duration="500" data-aos-delay="500" class="col-sm-12 col-md-6 col-lg-6">
          <p style="font-weight: 400; font-size: 18px;">Daha ne bekliyorsunuz?</p>
          <h1>Ücretsiz Deneyin</h1>
          <p class="text-container">
            Launch party pitch technology user experience innovator buzz stealth MVP business model.
          </p>
          <div class="buttons d-flex">
            <a href="#" class="google-play">
              <div class="store-btn">
                <img src="assets/img/google play.png" width="40" alt="Google Play store">
                <div>
                  <p style="font-size: smaller;">GET IT ON</p>
                  <p class="fw-500">Google Play</p>
                </div>
              </div>
            </a>
            <a href="#" class="apple-store">
              <div class="store-btn">
                <img src="assets/img/apple.png" width="40" alt="Apple store">
                <div>
                  <p style="font-size: smaller;">Available on the</p>
                  <p class="fw-500">App Store</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="phone-container col-sm-12 col-md-6 col-lg-6">
          <img data-aos-delay="500" data-aos="fade-left" data-aos-duration="1000" src="assets/img/phone.png" alt="">
        </div>
      </div>
    </section>
  </main>

  <PageFooter />

</template>

<script>
// import PageHeader from './components/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'

import AOS from "aos";

export default {
  name: 'App',
  data() {
    return {
      scrolled: false,
    }
  },
  components: {
    PageFooter
  },
  mounted() {
    AOS.init();
    this.updateDisplay();
    window.addEventListener('resize', this.updateDisplay);
    this.initializeMenu();

    window.addEventListener('scroll', this.handleScroll);

    document.querySelectorAll('.question').forEach(question => {
      question.addEventListener('click', () => {
        // Diğer açık soruları kapat ve active sınıfını kaldır
        document.querySelectorAll('.answer').forEach(answer => {
          if (answer !== question.querySelector('.answer')) {
            answer.style.maxHeight = null;
            answer.classList.remove('active');
            answer.parentElement.classList.remove('active');
          }
        });

        // Seçili sorunun cevabını aç veya kapat
        const answer = question.querySelector('.answer');
        if (question.classList.contains('active')) {
          answer.style.maxHeight = null;
          answer.classList.remove('active');
          question.classList.remove('active');
        } else {
          answer.style.maxHeight = (answer.scrollHeight + 20) + "px"; // Ekstra alan ekleme
          answer.classList.add('active');
          question.classList.add('active');
        }
      });
    });
  },
  methods: {
    updateDisplay() {
      if (window.innerWidth < 767) {
        document.getElementById('show-more').style.display = 'block';
        document.getElementById('hide').style.display = 'none';
        document.querySelector('.more').style.display = 'none';

        document.getElementById('show-more').addEventListener('click', function () {
          this.style.display = 'none';
          document.getElementById('hide').style.display = 'block';
          document.querySelector('.more').style.display = 'block';
        });

        document.getElementById('hide').addEventListener('click', function () {
          this.style.display = 'none';
          document.getElementById('show-more').style.display = 'block';
          document.querySelector('.more').style.display = 'none';
        });

      } else {
        document.getElementById('show-more').style.display = 'none';
        document.getElementById('hide').style.display = 'none';
        document.querySelector('.more').style.display = 'block';
      }
    },
    initializeMenu() {
      const mobileMenu = document.getElementById('mobile-menu');
      const navLinks = document.querySelector('.nav-links');
      const header = document.getElementById('head');
      const navItems = document.querySelectorAll('.nav-links li');

      mobileMenu.addEventListener('click', () => {
        this.toggleMenu(navLinks, header);
      });

      navItems.forEach(item => {
        item.addEventListener('click', () => {
          this.closeMenu(navLinks, header);
        });
      });
    },
    toggleMenu(navLinks, header) {
      navLinks.classList.toggle('active');
      header.classList.toggle('mobile-active');
    },
    closeMenu(navLinks, header) {
      if (navLinks.classList.contains('active')) {
        navLinks.classList.remove('active');
        header.classList.remove('mobile-active');
      }
    },
    scrollToElement(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    handleScroll() {
      var header = document.getElementById('head');
      if (window.scrollY > 50) {
        header.classList.add('scrolled');
        this.scrolled = true;
      } else {
        header.classList.remove('scrolled');
        this.scrolled = false;
      }
    }
  }
}
</script>
